import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import GuideInfo from './GuideInfo';
import { Lock, BarChart2, QrCode, Wifi, Share2, Eye, ToggleRight, ToggleLeft } from 'lucide-react';

const GuideItem = ({ guide, onShareClick }) => {
  const { t, i18n } = useTranslation();

  const getBestTitle = (guide) => {
    const languageOrder = [i18n.language, 'en', 'ko', 'ja', 'zh'];
    for (const lang of languageOrder) {
      if (guide[`title_${lang}`]) {
        return guide[`title_${lang}`];
      }
    }
    return t('untitledGuide');
  };

  const handleShareClick = (type) => {
    onShareClick(guide._id, type);
  };

  return (
    <li className="border-b border-gray-200 last:border-b-0">
      <div className="px-4 py-4 sm:px-6">
        <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
          <div className="flex-1 min-w-0 mb-2 sm:mb-0">
            <h3 className="text-lg font-medium text-indigo-600 truncate flex items-center">
              <Link to={`/guides/${guide._id}`} className="hover:underline">
                {getBestTitle(guide)}
              </Link>
              {guide.is_private && (
                <Lock className="ml-2 h-4 w-4 text-gray-500" aria-label={t('privateGuide')} />
              )}
            </h3>
            <GuideInfo guide={guide} />
          </div>
          <div className="flex items-center justify-between sm:justify-end w-full sm:w-auto">
            <div className="sm:mr-4">
              {guide.active ? (
                <span className="px-2 inline-flex items-center text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                  <ToggleRight className="h-4 w-4 mr-1" aria-label={t('guide.active')} /> {t('guide.active')}
                </span>
              ) : (
                <span className="px-2 inline-flex items-center text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                  <ToggleLeft className="h-4 w-4 mr-1" aria-label={t('guide.inactive')} /> {t('guide.inactive')}
                </span>
              )}
            </div>
            <div className="flex flex-wrap justify-end gap-2 sm:gap-3">
              <Link to={`/guides/${guide._id}/analysis`} className="flex items-center text-indigo-600 hover:text-indigo-900">
                <BarChart2 className="w-5 h-5 sm:w-4 sm:h-4" />
                <span className="hidden sm:inline ml-1">{t('statistics')}</span>
              </Link>
              <button onClick={() => handleShareClick('qr')} className="flex items-center text-indigo-600 hover:text-indigo-900">
                <QrCode className="w-5 h-5 sm:w-4 sm:h-4" />
                <span className="hidden sm:inline ml-1">QR</span>
              </button>
              <button onClick={() => handleShareClick('nfc')} className="flex items-center text-indigo-600 hover:text-indigo-900">
                <Wifi className="w-5 h-5 sm:w-4 sm:h-4" />
                <span className="hidden sm:inline ml-1">NFC</span>
              </button>
              <button onClick={() => handleShareClick('code')} className="flex items-center text-indigo-600 hover:text-indigo-900">
                <Share2 className="w-5 h-5 sm:w-4 sm:h-4" />
                <span className="hidden sm:inline ml-1">{t('shareCode')}</span>
              </button>
              <Link to={`/guides/${guide._id}`} className="flex items-center text-indigo-600 hover:text-indigo-900">
                <Eye className="w-5 h-5 sm:w-4 sm:h-4" />
                <span className="hidden sm:inline ml-1">{t('view')}</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};

export default GuideItem;