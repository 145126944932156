import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useTranslatedRoutes } from '@/routes';
import { Book, PieChart, Settings, ChevronRight, ChevronLeft } from 'lucide-react';
import { useSidebar } from '@contexts/SidebarContext';

const Sidebar = () => {
  const { t } = useTranslation();
  const routes = useTranslatedRoutes();
  const { isCollapsed, setIsCollapsed } = useSidebar();

  const sidebarRoutes = routes.filter(route =>
    ['guides', 'analysis', 'settings'].includes(route.key)
  ).map(route => ({
    ...route,
    icon: {
      guides: Book,
      analysis: PieChart,
      settings: Settings
    }[route.key]
  }));

  return (
    <aside className={`bg-gradient-to-b from-indigo-800 to-indigo-900 text-white h-screen shadow-lg transition-transform duration-300 
      ${isCollapsed ? 'lg:w-16 w-16' : 'lg:w-64 w-64'} flex-shrink-0`}>
      <div className="p-6 border-b border-indigo-700 flex items-center justify-between">
        {!isCollapsed && (
          <Link to="/guides" className="flex items-center">
            <h1 className="text-3xl font-bold text-indigo-100">{t('common.name')}</h1>
          </Link>
        )}
        <button
          onClick={() => setIsCollapsed(prev => !prev)}
          className="text-indigo-100 focus:outline-none"
        >
          {isCollapsed ? <ChevronRight/> : <ChevronLeft/>}
        </button>
      </div>
      <nav className="mt-8">
        {sidebarRoutes.map((route) => {
          const Icon = route.icon;
          return (
            <NavLink
              key={route.path}
              to={route.path}
              className={({ isActive }) =>
                `flex items-center py-3 px-6 transition-all duration-200 relative ${
                  isActive
                    ? 'text-white bg-indigo-700'
                    : 'text-indigo-200 hover:bg-indigo-700 hover:text-white'
                }`
              }
            >
              {({ isActive }) => (
                <>
                  <span className={`absolute left-0 top-0 bottom-0 w-1 bg-indigo-300 transition-all duration-200 ${isActive ? 'opacity-100' : 'opacity-0'}`} />
                  <Icon className={` ${isCollapsed ? 'text-lg' : 'mr-3 text-2xl'}`} size={isCollapsed ? 24 : 28} />
                  <span className={`font-medium ${isCollapsed ? 'hidden' : 'block'}`}>{route.name}</span>
                </>
              )}
            </NavLink>
          );
        })}
      </nav>
    </aside>
  );
};

export default Sidebar;
