import React, { useEffect, useState, useRef } from "react";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from "react-router-dom";
import { useGetGuideQuery, useNewGuideMutation, useUpdateGuideMutation } from "@services/guideService";
import Layout from "@components/manage/Layout";
import { X, ToggleRight, Lock } from "lucide-react";
import constants from "@constants";
import InfoTooltip from "@components/common/InfoTooltip";
import GuidePreview from "@components/manage/guide/GuidePreview";
import LoadingSpinner from "@components/LoadingSpinner";
import LoadingOverlay from "@components/common/LoadingOverlay";
import config from "@config";

const GuideForm = () => {
  const { guide_id } = useParams();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [image, setImage] = useState(null);
  const [deleteImageId, setDeleteImageId] = useState(null);
  const [selectedLang, setSelectedLang] = useState('en');

  const fileInputRef = useRef(null);

  const [newGuide, { isLoading: isNewing, isError: newError }] = useNewGuideMutation();
  const [updateGuide, { isLoading: isUpdating, isError: updateError }] = useUpdateGuideMutation();  
  const {data: existingGuide, isLoading: isLoadingGuide } = useGetGuideQuery(guide_id, { 
    skip: !guide_id,
    refetchOnMountOrArgChange: true 
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(null);

  const validationSchema = Yup.object().shape({
    category: Yup.string().required(t('guide.validation.category_required')),
    support_language: Yup.array().of(Yup.string()).min(1, t('guide.validation.language_required')),
    is_private: Yup.boolean().required(),
    password: Yup.string().when('is_private', {
      is: true,
      then: schema => schema.required(t('guide.validation.password_required')),
      otherwise: schema => schema.optional(),
    }),
    duration: Yup.object().shape({
      hours: Yup.number().min(0).max(23).required(t('guide.validation.hours_required')),
      minutes: Yup.number().min(0).max(59).required(t('guide.validation.minutes_required')),
    }).test('duration', t('guide.validation.duration_required'), function(value) {
      return value.hours >= 0 && value.minutes >= 0 && (value.hours > 0 || value.minutes > 0);
    }),
    active: Yup.boolean().required(),
    title_en: Yup.string().when('support_language', {
      is: (val) => val && val.includes('en'),
      then: schema => schema.required(t('guide.validation.title_required')),
    }),
    title_ko: Yup.string().when('support_language', {
      is: (val) => val && val.includes('ko'),
      then: schema => schema.required(t('guide.validation.title_required')),
    }),
    title_zh: Yup.string().when('support_language', {
      is: (val) => val && val.includes('zh'),
      then: schema => schema.required(t('guide.validation.title_required')),
    }),
    title_ja: Yup.string().when('support_language', {
      is: (val) => val && val.includes('ja'),
      then: schema => schema.required(t('guide.validation.title_required')),
    }),
    subtitle_en: Yup.string().when('support_language', {
      is: (val) => val && val.includes('en'),
      then: schema => schema.required(t('guide.validation.subtitle_required')),
    }),
    subtitle_ko: Yup.string().when('support_language', {
      is: (val) => val && val.includes('ko'),
      then: schema => schema.required(t('guide.validation.subtitle_required')),
    }),
    subtitle_zh: Yup.string().when('support_language', {
      is: (val) => val && val.includes('zh'),
      then: schema => schema.required(t('guide.validation.subtitle_required')),
    }),
    subtitle_ja: Yup.string().when('support_language', {
      is: (val) => val && val.includes('ja'),
      then: schema => schema.required(t('guide.validation.subtitle_required')),
    }),
  });

  const { register, handleSubmit, watch, reset, setValue, formState: { errors, isDirty } } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      category: "",
      support_language: guide_id ? [] : [i18n.language], // 기본값으로 한국어 선택
      is_private: false,
      password: "",
      duration: { hours: 0, minutes: 0 },
      active: true,
      title_en: "", title_ko: "", title_zh: "", title_ja: "",
      subtitle_en: "", subtitle_ko: "", subtitle_zh: "", subtitle_ja: "",
    }
  });

  const watchCategory = watch("category");
  const watchIsPrivate = watch("is_private");
  const watchSupportLanguage = watch("support_language");

  useEffect(() => {
    if (existingGuide) {
      reset({
        category: existingGuide.category,
        support_language: existingGuide.support_language,
        is_private: existingGuide.is_private.toString(),
        password: existingGuide.password,
        duration: {
          hours: parseInt(existingGuide.time.split(':')[0]),
          minutes: parseInt(existingGuide.time.split(':')[1])
        },
        is_private: existingGuide.is_private.toString(),
        active: existingGuide.active.toString(),
        title_en: existingGuide.title_en,
        title_ko: existingGuide.title_ko,
        title_zh: existingGuide.title_zh,
        title_ja: existingGuide.title_ja,
        subtitle_en: existingGuide.subtitle_en,
        subtitle_ko: existingGuide.subtitle_ko,
        subtitle_zh: existingGuide.subtitle_zh,
        subtitle_ja: existingGuide.subtitle_ja,        
      });

      if(existingGuide.cover_image) {
        setImage({
          id: existingGuide.cover_image._id,
          preview: `${config.API_SERVER_HOST}/files/guide/${existingGuide.cover_image.filename}?x=1280x720`,
          isExisting: true
        });
      }

      setTimeout(() => {
        if (existingGuide.support_language.includes(i18n.language)) {
          setSelectedLang(i18n.language);
        } else {
          setSelectedLang(existingGuide.support_language[0]);
        }
      });
    }
  }, [existingGuide, reset]);

  useEffect(() => {
    if(!watchSupportLanguage.includes(selectedLang)) {
      setSelectedLang(watchSupportLanguage[0]);
    }
  }, [watchSupportLanguage]);

  const guideData = {
    category: watchCategory,
    image: image?.preview,
    support_language: watchSupportLanguage,
    title_en: watch('title_en') || '',
    title_ko: watch('title_ko') || ' ',
    title_zh: watch('title_zh') || ' ',
    title_ja: watch('title_ja') || ' ',
    subtitle_en: watch('subtitle_en') || '',
    subtitle_ko: watch('subtitle_ko') || ' ',
    subtitle_zh: watch('subtitle_zh') || ' ',
    subtitle_ja: watch('subtitle_ja') || ' ',
    points: existingGuide?.points || [],
  };

  const onSubmit = async (data) => {
    if (isSubmitting) return;

    setIsSubmitting(true);
    setSubmitError(null);

    const formData = new FormData();
  
    const hours = String(data.duration.hours).padStart(2, '0');
    const minutes = String(data.duration.minutes).padStart(2, '0');
    const time = `${hours}:${minutes}`;

    Object.entries(data).forEach(([key, value]) => {
      if (key === 'duration') {
        formData.append('time', time);
      } else if (key === 'support_language') {
        value.forEach(lang => formData.append('support_language[]', lang));
      } else if (key === 'is_private' || key === 'active') {
        formData.append(key, value == true || value == 'true');
      } else {
        formData.append(key, value);
      }
    });
  
    if (image && !image.isExisting) {
      const response = await fetch(image.preview);
      const blob = await response.blob();
      const fileName = 'file_' + new Date().getTime() + '.' + blob.type.split('/')[1];
      formData.append('image', blob, fileName);
    }

    if (deleteImageId) {
      formData.append('delete_image_id', deleteImageId);
    }

    // formData.forEach((value, key) => {
    //   console.log(key, value);
    // });

    try {
      if (guide_id) {
        await updateGuide({ id: guide_id, guide: formData }).unwrap();
        navigate(`/guides/${guide_id}`);
      } else {
        const result = await newGuide(formData).unwrap();
        navigate(`/guides/${result.id}`);
      }
    } catch (err) {
      console.error('Failed to save guide:', err);
      setSubmitError(t('guide.submit_error'));
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type.substr(0, 5) === "image") {
      setImage({
        preview: URL.createObjectURL(file),
        isExisting: false
      });
    } else {
      setImage(null);
    }
  };

  const handleRemoveImage = () => {
    if(image.isExisting) {
      setDeleteImageId(image.id);
    }
    setImage(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleLanguageChange = (e) => {
    const { value, checked } = e.target;
    const currentLanguages = watchSupportLanguage;
    
    if (checked && !currentLanguages.includes(value)) {
      setValue('support_language', [...currentLanguages, value]);
    } else if (!checked && currentLanguages.includes(value)) {
      if (currentLanguages.length > 1) {
        setValue('support_language', currentLanguages.filter(lang => lang !== value));
      } else {
        // 마지막 언어를 선택 해제하려고 할 때
        e.preventDefault(); // 체크박스 상태 변경 방지
      }
    }
  };

  if (isLoadingGuide) {
    return <LoadingSpinner isLoading={true} />;
  }

  return (
   <Layout>
      <LoadingOverlay isLoading={isSubmitting} />
      <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8 gap-4 grid grid-cols-1 md:grid-cols-4 2xl:grid-cols-3">
        <div className="col-span-1 md:col-span-2 2xl:col-span-2">
         <div className="bg-white shadow overflow-hidden sm:rounded-lg">
          <form onSubmit={handleSubmit(onSubmit)} className="p-6">
            <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <label htmlFor="category" className="font-semibold font-medium text-gray-700">
                  {t('guide.category')}
                </label>
                <select
                  id="category"
                  {...register("category")}
                  className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                >
                  <option value="">{t('guide.select_category')}</option>
                  <option value="museum">{t('guide.categories.museum')}</option>
                  <option value="art_gallery">{t('guide.categories.art_gallery')}</option>
                  <option value="historical_site">{t('guide.categories.historical_site')}</option>
                  <option value="nature_and_park">{t('guide.categories.nature')}</option>
                  <option value="city_tour">{t('guide.categories.city_tour')}</option>
                </select>
                {errors.category && <p className="mt-2 text-sm text-red-600">{errors.category.message}</p>}
              </div>
            </div>

            <div className="my-6">
              <label htmlFor="guide-image" className="font-semibold font-medium text-gray-700">
                {t('guide.representative_image')}
              </label>
              <p className="mt-1 text-sm text-gray-500">
                {t('guide.image_guidance')}
              </p>
              <div className="mt-1 flex items-center">
                <div className="relative w-full max-w-md h-48 bg-gray-100 flex items-center justify-center relative">
                {/* <div className="w-72 h-40 overflow-hidden rounded-lg bg-gray-100 flex items-center justify-center relative"> */}
                  {image ? (
                    <>
                      <img src={image.preview} alt="Preview" className="w-full h-full object-cover rounded-lg" />
                      <button
                        type="button"
                        onClick={handleRemoveImage}
                        className="absolute top-2 right-2 bg-red-500 text-white rounded-full p-1 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                      >
                        <X className="w-4 h-4" />
                      </button>
                    </>
                  ) : (
                    constants.common.CATEGORY_IMAGE_MAP[watchCategory] ? (
                      <img src={constants.common.CATEGORY_IMAGE_MAP[watchCategory]} alt="Preview" className="w-full h-full object-cover rounded-lg"/>
                    ) : (
                      <svg className="h-12 w-12 text-gray-300" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                      </svg>
                    )
                  )}
                </div>
              </div>
              <p className="mt-2 text-sm text-gray-500">
                {t('guide.image_aspect_ratio_guidance')}
              </p>
              <p className="text-sm font-medium text-yellow-800">
                {t('guide.default_image_notice')}
              </p>
              <div className="mt-2 flex space-x-2">
                <button
                  type="button"
                  className="inline-flex items-center px-4 py-1 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={() => fileInputRef.current.click()}
                >
                  <svg className="-ml-1 mr-2 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fillRule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clipRule="evenodd" />
                  </svg>
                  {t('guide.upload_image')}
                </button>
                <input
                  ref={fileInputRef}
                  type="file"
                  id="guide-image"
                  name="guide-image"
                  accept="image/*"
                  className="hidden"
                  onChange={handleImageChange}
                />
              </div>
            </div>

            <div className="mt-6">
              <label htmlFor="duration" className="font-semibold font-medium text-gray-700">
                  {t('guide.estimated_duration')}
                </label>
                <div className="mt-2 flex rounded-md shadow-sm">
                  <input
                    type="number"
                    {...register("duration.hours")}
                    className="px-2 py-1 focus:ring-indigo-500 focus:border-indigo-500 flex-grow-0 w-13 rounded-none border border-r-0 border-gray-300 sm:text-sm pl-3"
                    placeholder="00"
                    min="0"
                    max="23"
                  />
                  <span className="inline-flex items-center px-3 rounded-none border border-l-0 border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                    {t('guide.hours')}
                  </span>
                  <input
                    type="number"
                    {...register("duration.minutes")}
                    className="px-2 py-1 focus:ring-indigo-500 focus:border-indigo-500 flex-grow-0 w-13 rounded-none border border-l-0 border-gray-300  sm:text-sm pl-3"
                    placeholder="00"
                    min="0"
                    max="59"
                  />
                  <span className="inline-flex items-center px-3 rounded-none rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                    {t('guide.minutes')}
                  </span>
                </div>
                {errors.duration && 
                  <p className="mt-2 text-sm text-red-600">
                    {errors.duration.message || t('guide.validation.duration_required')}
                  </p>}
              </div>

            <div className="mt-6">
              <label className="font-semibold text-gray-700">{t('guide.supported_languages')}</label>
              <div className="grid grid-cols-2 sm:grid-cols-4 gap-4 mt-2">
                {constants.common.LANGUAGES.map((lang) => (
                  <div key={lang.code} className="flex items-center">
                    <input
                      type="checkbox"
                      id={`lang-${lang.code}`}
                      value={lang.code}
                      {...register("support_language")}
                      onChange={handleLanguageChange}
                      checked={watchSupportLanguage.includes(lang.code)}
                      className="h-5 w-5 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                    />
                    <label htmlFor={`lang-${lang.code}`} className="ml-2 block text-sm text-gray-900">
                      {lang.name}
                    </label>
                  </div>
                ))}
              </div>
              {errors.support_language && <p className="mt-2 text-sm text-red-600">{errors.support_language.message}</p>}
            </div>

            {watchSupportLanguage.length > 0 && (
              <div className="mt-6 space-y-3">
                {constants.common.LANGUAGES.filter(lang => watchSupportLanguage.includes(lang.code)).map((lang) => (
                  <div key={lang.code} className="bg-gray-50 p-4 rounded-lg shadow-sm">
                    <h3 className="text-lg font-medium text-gray-900 mb-4">
                      {lang.name}
                    </h3>
                    <div className="mb-4">
                      <label htmlFor={`title-${lang.code}`} className="font-medium text-gray-700 flex items-center">
                        {t('guide.main_title')}
                        <InfoTooltip content={t('guide.main_title_info')} />
                      </label>
                      <input
                        type="text"
                        id={`title-${lang.code}`}
                        {...register(`title_${lang.code}`, {
                          onChange: () => {
                            if (lang.code !== selectedLang) {
                              setSelectedLang(lang.code);
                            }
                          }
                        })}
                        className="mt-1 px-2 py-1 w-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                        placeholder={t(`guide.placeholders.main_title.${lang.code}`)}
                      />
                      {errors[`title_${lang.code}`] && <p className="mt-2 text-sm text-red-600">{errors[`title_${lang.code}`].message}</p>}
                    </div>
                    <div>
                      <label htmlFor={`subtitle-${lang.code}`} className="font-medium text-gray-700 flex items-center">
                        {t('guide.subtitle')}
                        <InfoTooltip content={t('guide.subtitle_info')} />
                      </label>
                      <input
                        type="text"
                        id={`subtitle-${lang.code}`}
                        {...register(`subtitle_${lang.code}`, {
                          onChange: () => {
                            if (lang.code !== selectedLang) {
                              setSelectedLang(lang.code);
                            }
                          }
                        })}
                        className="mt-1 px-2 py-1 w-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                        placeholder={t(`guide.placeholders.subtitle.${lang.code}`)}
                      />
                      {errors[`subtitle_${lang.code}`] && <p className="mt-2 text-sm text-red-600">{errors[`subtitle_${lang.code}`].message}</p>}
                    </div>
                  </div>
                ))}
              </div>
            )}

            <div className="mt-8 border-t border-gray-200 pt-6">
              <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-2">
                <div className="sm:col-span-1">
                  <label className="flex items-center gap-1 font-semibold text-gray-700 mb-1"><Lock className="h-4 w-4"/>{t('guide.visibility_setting')}</label>
                  <select
                    {...register("is_private")}
                    className="mt-2 block w-full pl-3 pr-10 py-2 border border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 rounded-md"
                  >
                    <option value="false">{t('guide.public')}</option>
                    <option value="true">{t('guide.private')}</option>
                  </select>
                </div>
                {watchIsPrivate == 'true' && (
                  <div className="sm:col-span-1">
                    <label htmlFor="password" className="font-semibold text-gray-700">
                      {t('guide.password')}
                    </label>
                    <input
                      type="password"
                      id="password"
                      {...register("password")}
                      className="mt-2 px-2 py-1 w-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                      placeholder={t('guide.enter_password')}
                    />
                    {errors.password && <p className="mt-1 text-xs text-red-600">{errors.password.message}</p>}
                  </div>
                )}
                <div className="sm:col-span-1">
                  <label className="flex items-center gap-1 font-semibold text-gray-700 mb-1"><ToggleRight/>{t('guide.activation_status')}</label>
                  <select
                    {...register("active")}
                    className="mt-1 w-full pl-3 pr-10 py-2 border border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 rounded-md"
                  >
                    <option value="true">{t('guide.active')}</option>
                    <option value="false">{t('guide.inactive')}</option>
                  </select>
                </div>
              </div>
            </div>

            {submitError && (
              <div className="mt-4 text-red-600">
                {submitError}
              </div>
            )}

            <div className="mt-6 flex justify-end">
              <button
                type="submit"
                disabled={isSubmitting}
                className={`inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white ${
                  isSubmitting ? 'bg-indigo-400 cursor-not-allowed' : 'bg-indigo-600 hover:bg-indigo-700'
                } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
              >
                {isSubmitting 
                  ? t('guide.submitting') 
                  : (guide_id ? t('guide.update_guide') : t('guide.create_guide'))}
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="hidden col-span-1 md:col-span-2 2xl:col-span-1 md:block">
        <div className="sticky top-4">
          <GuidePreview 
            guideData={guideData} 
            selectedLang={selectedLang} 
            onLanguageChange={setSelectedLang}
          />
        </div>
      </div>
    </div>
  </Layout>
  );
};

export default GuideForm;