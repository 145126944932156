import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from "react-router-dom";
import * as Yup from 'yup';
import Layout from "@components/manage/Layout";
import { useSelector, useDispatch } from "react-redux";
import { useChangePasswordMutation, useUpdateUserMutation } from "../../services/settingsService";
import constants from "@constants";
import Modal from "@components/common/Modal";
import Toast from "@components/common/Toast";
import Helpers from "@helpers";

const Settings = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [isSubscribed, setIsSubscribed] = useState(true);
  const user = useSelector(state => state.auth.user);

  const [changePassword] = useChangePasswordMutation();
  const [updateUser] = useUpdateUserMutation();

  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [showToast, setShowToast] = useState(false);

  // Yup schema for password change form
  const passwordSchema = Yup.object().shape({
    oldPassword: Yup.string().required(t('settings.oldPasswordRequired')),
    newPassword: Yup.string()
      .min(8, t('settings.passwordMinLength'))
      .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/, t('settings.passwordRequirements'))
      .required(t('settings.newPasswordRequired')),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], t('settings.passwordsMismatch'))
      .required(t('settings.confirmPasswordRequired')),
  });

  const { register, handleSubmit, formState: { errors }, reset } = useForm({
    resolver: yupResolver(passwordSchema)
  });

  const handleCancelSubscription = () => {
    setIsConfirmModalOpen(true);
  };

  const confirmCancelSubscription = () => {
    setIsSubscribed(false);
    setIsConfirmModalOpen(false);
    setToastMessage(t('settings.cancelSuccess'));
    setShowToast(true);
  };

  const handleChangeLanguage = async (e) => {
    try {
      i18n.changeLanguage(e.target.value);

      const result = await updateUser({
        lang_code: e.target.value
      });

      if ('data' in result) {
        setToastMessage(t('settings.languageChanged'));
        setShowToast(true);
      } else if ('error' in result) {
        console.error('Update failed:', result.error);
        // Handle error
      }
    } catch (err) {
      console.error('An error occurred:', err);
      // Handle unexpected errors
    }
  }

  const onPasswordSubmit = async (data) => {
    try {
      await changePassword({
        oldPassword: data.oldPassword,
        newPassword: data.newPassword
      });
      setIsPasswordModalOpen(false);
      setToastMessage(t('settings.passwordChangeSuccess'));
      setShowToast(true);
      reset(); // Reset form fields
    } catch (error) {
      setToastMessage(t('settings.passwordChangeError'));
      setShowToast(true);
    }
  };

  return (
    <Layout>
      <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        {/* Account Settings Section */}
        <div className="bg-white shadow overflow-hidden sm:rounded-lg mb-6">
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              {t('settings.accountSettings')}
            </h3>
          </div>
          <div className="border-t border-gray-200">
            <dl>
              <div className="bg-gray-50 px-4 py-4 sm:px-6 flex items-center">
                <dt className="text-sm font-medium text-gray-500 w-2/12">{t('settings.email')}</dt>
                <dd className="text-sm text-gray-900">{user.email}</dd>
              </div>
              <div className="bg-gray-50 px-4 py-4 sm:px-6 flex items-center">
                <dt className="text-sm font-medium text-gray-500 w-2/12">{t('settings.password')}</dt>
                <dd className="text-sm text-gray-900">
                  <button 
                    className="bg-indigo-600 text-white px-3 py-1 rounded-md hover:bg-indigo-700 transition duration-300"
                    onClick={() => setIsPasswordModalOpen(true)}
                  >
                    {t('settings.changePassword')}
                  </button>
                </dd>
              </div>
            </dl>
          </div>
        </div>

        {/* Payment Info Section */}
        <div className="bg-white shadow overflow-hidden sm:rounded-lg mb-6">
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              {t('settings.paymentInfo')}
            </h3>
          </div>
          <div className="border-t border-gray-200">
            <dl>
              <div className="bg-gray-50 px-4 py-4 sm:px-6 flex items-center">
                <dt className="text-sm font-medium text-gray-500 w-2/12">{t('settings.currentPlan')}</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {isSubscribed ? t('settings.proPlan', { price: '29,900' }) : t('settings.freePlan')}
                </dd>
              </div>
              {isSubscribed && (
                <div className="bg-gray-50 px-4 py-4 sm:px-6 flex items-center">
                  <dt className="text-sm font-medium text-gray-500 w-2/12">{t('settings.nextBillingDate')}</dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    2024년 9월 22일
                  </dd>
                </div>
              )}
                <div className="bg-gray-50 px-4 py-4 sm:px-6 flex items-center">
                <dt className="text-sm font-medium text-gray-500 w-2/12">{t('settings.subscriptionManagement')}</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {isSubscribed ? (
                    <>
                      <button
                        onClick={() => navigate('/settings/payment')}
                        className="bg-indigo-600 text-white px-3 py-1 rounded-md hover:bg-indigo-700 transition duration-300 mr-2"
                      >
                        {t('settings.changePlan')}
                      </button>
                      <button
                        onClick={handleCancelSubscription}
                        className="bg-red-600 text-white px-3 py-1 rounded-md hover:bg-red-700 transition duration-300"
                      >
                        {t('settings.cancelSubscription')}
                      </button>
                    </>
                  ) : (
                    <Link
                      to="/settings/payment"
                      className="bg-indigo-600 text-white px-3 py-1 rounded-md hover:bg-indigo-700 transition duration-300"
                    >
                      {t('settings.subscribeToPro')}
                    </Link>
                  )}
                </dd>
              </div>
            </dl>
          </div>
        </div>

        {/* Language Settings Section */}
        <div className="bg-white shadow overflow-hidden sm:rounded-lg mb-6">
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              {t('settings.languageSettings')}
            </h3>
          </div>
          <div className="border-t border-gray-200">
            <dl>
              <div className="bg-gray-50 px-4 py-4 sm:px-6 flex items-center">
                <dt className="text-sm font-medium text-gray-500 w-2/12">{t('settings.defaultLanguage')}</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  <select 
                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" 
                    onChange={handleChangeLanguage}
                    value={i18n.language}
                  >
                    {constants.common.LANGUAGES.map(lang => (
                      <option key={lang.code} value={lang.code}>{lang.name}</option>
                    ))}
                  </select>
                </dd>
              </div>
            </dl>
          </div>
        </div>

        {/* Password Change Modal */}
        <Modal
          isOpen={isPasswordModalOpen}
          onClose={() => {
            setIsPasswordModalOpen(false);
            reset(); // Reset form when closing modal
          }}
          title={t('settings.changePassword')}
        >
          <form onSubmit={handleSubmit(onPasswordSubmit)} className="space-y-4">
            <div>
              <label htmlFor="oldPassword" className="block text-sm font-medium text-gray-700">
                {t('settings.oldPassword')}
              </label>
              <input
                type="password"
                id="oldPassword"
                {...register('oldPassword')}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
              {errors.oldPassword && <p className="mt-1 text-sm text-red-600">{errors.oldPassword.message}</p>}
            </div>
            <div>
              <label htmlFor="newPassword" className="block text-sm font-medium text-gray-700">
                {t('settings.newPassword')}
              </label>
              <input
                type="password"
                id="newPassword"
                {...register('newPassword')}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
              {errors.newPassword && <p className="mt-1 text-sm text-red-600">{errors.newPassword.message}</p>}
            </div>
            <div>
              <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700">
                {t('settings.confirmPassword')}
              </label>
              <input
                type="password"
                id="confirmPassword"
                {...register('confirmPassword')}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
              {errors.confirmPassword && <p className="mt-1 text-sm text-red-600">{errors.confirmPassword.message}</p>}
            </div>
            <div className="mt-6">
              <button
                type="submit"
                className="w-full inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-indigo-500"
              >
                {t('settings.changePassword')}
              </button>
            </div>
          </form>
        </Modal>

        {/* Confirm Subscription Cancellation Modal */}
        <Modal
          isOpen={isConfirmModalOpen}
          onClose={() => setIsConfirmModalOpen(false)}
          title={t('settings.confirmCancellation')}
        >
          <p className="mb-4">{Helpers.formatMessage(t('settings.cancelConfirmMessage'))}</p>
          <div className="flex justify-end space-x-2">
            <button
              onClick={() => setIsConfirmModalOpen(false)}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 border border-gray-300 rounded-md hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-gray-500"
            >
              {t('common.cancel')}
            </button>
            <button
              onClick={confirmCancelSubscription}
              className="px-4 py-2 text-sm font-medium text-white bg-red-600 border border-transparent rounded-md hover:bg-red-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-red-500"
            >
              {t('settings.confirmCancel')}
            </button>
          </div>
        </Modal>

        {/* Toast Message */}
        <Toast
          message={toastMessage}
          isVisible={showToast}
          onClose={() => setShowToast(false)}
        />
      </div>
    </Layout>
  );
};

export default Settings;