import React, { useState, useEffect } from "react";
import Layout from "@components/manage/Layout";
import StatCard from "@components/manage/analysis/StatCard";
import DailyUsersChart from "@components/manage/analysis/DailyUsersChart";
import TopGuidesChart from "@components/manage/analysis/TopGuidesChart";
import OverviewCard from "@components/manage/analysis/OverviewCard";
import { useTranslation } from 'react-i18next';

const Analysis = () => {
  const { t } = useTranslation();
  const [dailyUsersData, setDailyUsersData] = useState([]);

  useEffect(() => {
    const generateDailyUsersData = () => {
      const data = [];
      for (let i = 6; i >= 0; i--) {
        const date = new Date();
        date.setDate(date.getDate() - i);
        data.push({
          name: date.toLocaleDateString('ko-KR', { month: '2-digit', day: '2-digit' }),
          users: Math.floor(Math.random() * 300) + 100 // Random data between 100 and 400
        });
      }
      return data;
    };

    setDailyUsersData(generateDailyUsersData());
  }, []);

  const topGuidesData = [
    { name: '가이드 A', uses: 1200 },
    { name: '가이드 B', uses: 1000 },
    { name: '가이드 C', uses: 800 },
    { name: '가이드 D', uses: 700 },
    { name: '가이드 E', uses: 600 },
  ];

  return (
    <Layout>
      <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3 mb-8">
          <OverviewCard icon="fa-map-marked-alt" title={t('guides.overview.totalGuides')} value={0} bgColor="bg-indigo-500" />
          <OverviewCard icon="fa-users" title={t('guides.overview.usersThisMonth')} value={0} bgColor="bg-green-500" />
          <OverviewCard icon="fa-headphones" title={t('guides.overview.totalPlayingTime')} value={0} bgColor="bg-yellow-500" />
        </div>

        <div className="grid grid-cols-1 gap-5 sm:grid-cols-2">
          <DailyUsersChart data={dailyUsersData} />
          <TopGuidesChart data={topGuidesData} />
        </div>
      </div>
    </Layout>
  );
};

export default Analysis;