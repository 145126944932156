import React, { useState, useEffect } from "react";
import Layout from "@components/manage/Layout";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import OverviewCard from "@components/manage/analysis/OverviewCard";
import UserListItem from "@components/manage/guide/analysis/UserListItem";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const GuideAnalysis = () => {
  const { guide_id } = useParams();
  const { t } = useTranslation();
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    const generateDateLabels = () => {
      const labels = [];
      const data = [];
      for (let i = 6; i >= 0; i--) {
        const date = new Date();
        date.setDate(date.getDate() - i);
        labels.push(date.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit' }));
        data.push(Math.floor(Math.random() * 20)); // Random data for demonstration
      }
      return { labels, data };
    };

    const { labels, data } = generateDateLabels();

    setChartData({
      labels,
      datasets: [{
        label: t('dailyNewUsers'),
        data,
        borderColor: 'rgb(75, 192, 192)',
        tension: 0.1
      }]
    });
  }, [t]);

  const chartOptions = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true
      }
    }
  };

  return (
    <Layout>
      <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-2 mb-8">
          <OverviewCard icon="fa-users" title={t('totalAddedUsers')} value="0" bgColor="bg-green-500" />
          <OverviewCard icon="fa-headphones" title={t('averageUsageTime')} value={0} bgColor="bg-yellow-500" />
        </div>

        <div className="bg-white shadow rounded-lg p-6 mb-8">
          <h3 className="text-lg font-semibold mb-4">{t('dailyGuideAdditionTrend')}</h3>
          {chartData && <Line data={chartData} options={chartOptions} />}
        </div>

        <div className="bg-white shadow overflow-hidden sm:rounded-md">
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              {t('guideUserList')}
            </h3>
          </div>
          <ul className="divide-y divide-gray-200">
            <UserListItem
              name="홍길동"
              status="completed"
              date="2023-06-15"
              duration="1시간 30분"
            />
            {/* 추가 사용자 항목들 */}
          </ul>
        </div>
      </div>
    </Layout>
  );
};

export default GuideAnalysis;