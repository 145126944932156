import React, { useState, useMemo, useEffect } from "react";
import Layout from "@components/manage/Layout";
import { useParams, Link, Navigate } from "react-router-dom";
import { Edit, MapPin, BarChart2, Share2, Trash2, QrCode, Wifi } from "lucide-react";
import { useGetGuideQuery } from "@services/guideService";
import { useTranslation } from "react-i18next";
import GuidePreview from "@components/manage/guide/GuidePreview";
import ShareModal from "@components/manage/ShareModal";
import Dropdown from "@components/common/Dropdown";
import GuideMetaInfo from "../../../components/manage/guide/GuideMetaInfo";
import constants from "@constants";
import config from "@config";
import LoadingSpinner from "@components/LoadingSpinner";

const Guide = () => {
  const { guide_id } = useParams();
  const { t, i18n } = useTranslation();
  const { data: guideData, error, isLoading } = useGetGuideQuery(guide_id, { refetchOnMountOrArgChange: true });
  const [selectedLang, setSelectedLang] = useState('');
  const [selectedPoint, setSelectedPoint] = useState(null);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [shareModalType, setShareModalType] = useState('qr');
  
  useEffect(() => {
    if (guideData) {
      if (guideData.support_language.includes(i18n.language)) {
        setSelectedLang(i18n.language);
      } else {
        setSelectedLang(guideData.support_language[0]);
      }
    }
  }, [guideData]);

  const handlePointShareClick = (point, type) => {
    setSelectedPoint(point);
    setShareModalType(type);
    setIsShareModalOpen(true);
  };

  const handleShareClick = () => {
    setIsShareModalOpen(true);
  };

  const handleDeleteClick = () => {
    if (window.confirm(t('guide.deleteConfirm'))) {
      // deleteGuide(guide_id);
    }
  };

  const GuidePoints = ({ guideData, guide_id, t, i18n, onShareClick }) => {
    const getLocalizedContent = useMemo(() => {
      return (point, field) => {
        const currentLang = i18n.language;
        
        if (point[`${field}_${currentLang}`]) {
          return point[`${field}_${currentLang}`];
        }
    
        for (const lang of guideData.support_language) {
          if (point[`${field}_${lang}`]) {
            return `${point[`${field}_${lang}`]} (${lang})`;
          }
        }
    
        return point[`${field}_en`] ? `${point[`${field}_en`]} (en)` : 'N/A';
      };
    }, [guideData.support_language, i18n.language]);
  
    if (!guideData?.points?.length) {
      return (
        <div className="text-center py-10">
          <MapPin className="w-16 h-16 text-gray-400 mx-auto mb-4" />
          <h3 className="text-xl font-semibold text-gray-900 mb-2">{t('guide.noPoints')}</h3>
          <p className="text-gray-600 mb-6">
            {t('guide.addFirstPoint').split('\n').map((line, i) => (
              <React.Fragment key={i}>
                {line}
                <br />
              </React.Fragment>
            ))}
          </p>
          <Link 
            to={`/guides/${guide_id}/points/new`} 
            className="bg-indigo-600 text-white px-6 py-2 rounded-md hover:bg-indigo-700 transition duration-300"
          >
            + {t('guide.addFirstPointButton')}
          </Link>
        </div>
      );
    }
  
    return (
      <>
        <ul className="space-y-4">
          {guideData.points.map((point, index) => (
            <li key={point.id || index} className="border-b pb-4">
              <div className="flex justify-between items-center">
                <span className="font-medium">
                  {index + 1}. {getLocalizedContent(point, 'name')}
                </span>
                <div className="flex space-x-2">
                  <button
                    onClick={() => onShareClick(point, 'qr')}
                    className="flex items-center text-indigo-600 hover:text-indigo-800"
                  >
                    <QrCode className="w-4 h-4 mr-1" />
                    QR
                  </button>
                  <button
                    onClick={() => onShareClick(point, 'nfc')}
                    className="flex items-center text-indigo-600 hover:text-indigo-800"
                  >
                    <Wifi className="w-4 h-4 mr-1" />
                    NFC
                  </button>
                  <Link 
                    to={`/guides/${guide_id}/points/${point._id}/edit`}
                    className="flex items-center text-indigo-600 hover:text-indigo-800"
                  >
                    <Edit className="w-4 h-4 mr-1" />
                    {t('common.edit')}
                  </Link>
                </div>
              </div>
            </li>
          ))}
        </ul>
        <Link 
          to={`/guides/${guide_id}/points/new`} 
          className="mt-4 inline-block bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-700 transition duration-300"
        >
          + {t('guide.addNewPoint')}
        </Link>
      </>
    );
  };

  const handleLanguageChange = (langCode) => {
    setSelectedLang(langCode);
  };  

  if (isLoading) {
    return <LoadingSpinner isLoading={true} />;
  }

  if (error) {
    return <Navigate to="/guides" />;
  }

  const renderImage = () => {
    const imageUrl = (guideData.cover_image ? `${config.API_SERVER_HOST}/files/guide/${guideData.cover_image.filename}?x=1280x720` : '') || constants.common.CATEGORY_IMAGE_MAP[guideData.category];
    return (
      <div className="relative w-full max-w-md h-48">
        <img
          src={imageUrl}
          className="w-full h-full object-cover rounded-lg"
          loading="lazy"
        />
      </div>
    );
  };

  const LanguageSpecificTitles = ({ guideData, selectedLang, onLanguageChange }) => {
    return (
      <div className="mt-8">
        <h2 className="font-semibold mb-2">{t('guide.languageSpecificTitles')}</h2>
        <div className="space-y-4">
          <div className="flex flex-wrap gap-2 mb-4">
            {guideData.support_language.map((langCode) => {
              const language = constants.common.LANGUAGES.find(l => l.code === langCode)?.name || langCode;
              return (
                <button
                  key={langCode}
                  className={`px-4 py-2 rounded-md text-sm font-medium transition-colors ${
                    selectedLang === langCode
                      ? 'bg-indigo-600 text-white'
                      : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                  }`}
                  onClick={() => onLanguageChange(langCode)}
                >
                  {language}
                </button>
              );
            })}
          </div>
          <div className="border rounded-lg p-4 space-y-4">
            <div>
              <label className="font-medium text-gray-700 mb-1">{t('guide.title')}</label>
              <p className="text-sm text-gray-900 bg-gray-50 py-2 rounded">{guideData[`title_${selectedLang}`]}</p>
            </div>
            <div>
              <label className="font-medium text-gray-700 mb-1">{t('guide.subtitle')}</label>
              <p className="text-sm text-gray-900 bg-gray-50 py-2 rounded">{guideData[`subtitle_${selectedLang}`]}</p>
            </div>
          </div>
        </div>
      </div>
    );
  };


  const actionMenuItems = [
    {
      type: 'link',
      href: `/guides/${guide_id}/analysis`,
      icon: BarChart2,
      text: t('statistics')
    },
    {
      type: 'button',
      onClick: handleShareClick,
      icon: Share2,
      text: t('share')
    },
    {
      type: 'link',
      href: `/guides/${guide_id}/edit`,
      icon: Edit,
      text: t('common.edit')
    },
    {
      type: 'button',
      onClick: handleDeleteClick,
      icon: Trash2,
      text: t('common.delete'),
      className: 'text-red-700 hover:bg-gray-100 hover:text-red-900'
    }
  ];

  return (
    <Layout>
      <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8 gap-4 grid grid-cols-1 md:grid-cols-4 2xl:grid-cols-3">
        <div className="col-span-1 md:col-span-2 2xl:col-span-2">
          <div className="bg-white shadow-md rounded-lg p-6 mb-6">
            <div className="flex justify-between items-center mb-6">
              <GuideMetaInfo guideData={guideData}/>
              <Dropdown
                buttonText={t('common.menu')}
                menuItems={actionMenuItems}/>
            </div>
            <div className="mb-6">
              <h2 className="font-semibold text-gray-900 mb-2">{t('guide.mainImage')}</h2>
              {renderImage()}
            </div>
            <LanguageSpecificTitles 
              guideData={guideData} 
              selectedLang={selectedLang} 
              onLanguageChange={handleLanguageChange} 
            />
          </div>

          <div className="bg-white shadow-md rounded-lg p-6">
            <h2 className="font-semibold mb-2">{t('guide.guidePoints')}</h2>
            {/* <GuidePoints guideData={guideData} guide_id={guide_id} t={t} i18n={i18n}  /> */}
            <GuidePoints 
              guideData={guideData} 
              guide_id={guide_id} 
              t={t} 
              i18n={i18n}  
              onShareClick={handlePointShareClick}
            />
          </div>
        </div>
        <div className="hidden col-span-1 md:col-span-2 2xl:col-span-1 md:block">
          <div className="sticky top-4">
            <GuidePreview 
              guideData={{
                ...guideData,
                image: guideData.cover_image ? `${config.API_SERVER_HOST}/files/guide/${guideData.cover_image.filename}?x=1280x720` : '',
              }} 
              selectedLang={selectedLang} 
              onLanguageChange={handleLanguageChange}
            />
          </div>
        </div>
      </div>
      <ShareModal
        isOpen={isShareModalOpen}
        onClose={() => {
          setIsShareModalOpen(false);
          setSelectedPoint(null);
        }}
        guideId={guideData?._id}
        pointId={selectedPoint?._id}
        shareType="point"
        initialTab={shareModalType}
      />
    </Layout>
  );
};

export default Guide;
