import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Link, replace, useNavigate } from 'react-router-dom';
import { Eye, EyeOff, Apple, Chrome } from 'lucide-react';
import Layout from '@components/account/Layout';
import { useTranslation } from 'react-i18next';
import { useLoginMutation } from '@services/authService';
import { useDispatch } from 'react-redux';
import { setCredentials } from '../../store/slice/authSlice';
import Alert from '../../components/input/Alert';

const GoogleLogo = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" {...props}>
    <path fill="#4285F4" d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z" />
    <path fill="#34A853" d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z" />
    <path fill="#FBBC05" d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z" />
    <path fill="#EA4335" d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z" />
    <path fill="none" d="M1 1h22v22H1z" />
  </svg>
);

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [login, { isLoading }] = useLoginMutation();

  const validationSchema = Yup.object().shape({
    email: Yup.string().email(t('account.form.error.email')).required(t('account.form.error.required')),
    password: Yup.string().required(t('account.form.error.required')),
  });

  const { register, handleSubmit, setError, clearErrors, setValue, formState: { errors } } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const [showPassword, setShowPassword] = useState(false);

  const onSubmit = async (data) => {
    try {
      const result = await login({
        email: data.email,
        password: data.password,
      }).unwrap()

      i18n.changeLanguage(result?.user?.lang_code || 'en');
      
      if(result.user.is_verified_email){
        navigate('/guides', {replace: true});
      } else {
        navigate('/verify-email', {replace: true});
      }
    } catch (error) {
      console.error('Failed to login:', error);
      setError('loginfail', {
        code: error.data.code,
      });
    }
  };

  const handleInputChange = (e) => {
    clearErrors();
    setValue(e.target.name, e.target.value);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Layout>
      <div className="w-full max-w-md">
        <div className="bg-white rounded-lg shadow-md overflow-hidden p-6">
          <button className="w-full bg-white border border-gray-300 text-gray-700 py-2 px-4 rounded-lg hover:bg-gray-50 transition duration-300 flex items-center justify-center mb-4">
            <GoogleLogo className="w-5 h-5 mr-2" />
            {t('account.login.googleLogin')}
          </button>
          
          <button className="w-full bg-black text-white py-2 px-4 rounded-lg hover:bg-gray-900 transition duration-300 flex items-center justify-center mb-4">
            <Apple className="w-5 h-5 mr-2" />
            {t('account.login.appleLogin')}
          </button>
          
          <p className="text-xs text-gray-500 text-center mt-2 mb-4">
            {t('account.login.termsAgreement')} <br/>
            <Link to="/terms-of-service" className="text-indigo-600 hover:text-indigo-800">{t('account.login.termsOfService')}</Link> {t('account.login.and')}&nbsp;
            <Link to="/privacy-policy" className="text-indigo-600 hover:text-indigo-800">{t('account.login.privacyPolicy')}</Link>
          </p>
          
          <div className="relative my-6">
            <hr className="border-gray-300"/>
            <span className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white px-2 text-gray-500">{t('account.login.or')}</span>
          </div>
          
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-4">
              <label htmlFor="login-email" className="block text-gray-700 font-bold mb-2">{t('account.form.email')}</label>
              <input
                type="email"
                id="login-email"
                {...register('email', {
                  onChange: handleInputChange
                })}
                className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-indigo-500"
              />
              {errors.email && <Alert>{t(`account.form.error.${errors.email.type}`, { field: t('account.form.email') })}</Alert>}
            </div>
            <div className="mb-6">
              <label htmlFor="login-password" className="block text-gray-700 font-bold mb-2">{t('account.form.password')}</label>
              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  id="login-password"
                  {...register('password', {
                    onChange: handleInputChange
                  })}
                  className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-indigo-500 pr-10"
                />
                <button
                  type="button"
                  onClick={togglePasswordVisibility}
                  className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
                >
                  {showPassword ? <EyeOff className="h-5 w-5 text-gray-500" /> : <Eye className="h-5 w-5 text-gray-500" />}
                </button>
              </div>
              {errors.password && <Alert>{t(`account.form.error.${errors.password.type}`, { field: t('account.form.password') })}</Alert>}
              {errors.loginfail && <Alert>{t(`server_errors.${errors.loginfail.code}`)}</Alert>}
            </div>
            <button type="submit" className="w-full bg-indigo-600 text-white py-2 px-4 rounded-lg hover:bg-indigo-700 transition duration-300">{t('account.login.submit')}</button>
          </form>
          
          <div className="mt-4 text-center">
            <Link to="/password-reset-request" className="text-indigo-600 hover:text-indigo-800">{t('account.login.forgotPassword')}</Link>
          </div>
          
          <div className="mt-6 text-center">
            <p>{t('account.login.noAccount')} <Link to="/sign-up" className="text-indigo-600 hover:text-indigo-800 font-semibold">{t('account.signup.title')}</Link></p>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Login;