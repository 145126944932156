import React from 'react';

export const formatMessage = (message) => {
  return message.split('\n').map((line, index) => (
    <React.Fragment key={index}>
      {line}
      {index !== message.split('\n').length - 1 && <br />}
    </React.Fragment>
  ));
};

export default {
  formatMessage
}