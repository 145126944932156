import React, { useEffect, useState, useCallback, useRef } from "react";
import { set, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import Layout from "@components/manage/Layout";
import { useParams, useNavigate  } from "react-router-dom";
import { useGetGuideQuery } from "@services/guideService";
import { useNewPointMutation, useUpdatePointMutation } from "@services/pointService";
import MultiImageUpload from "@components/manage/MultiImageUpload";
import InfoTooltip from "@components/common/InfoTooltip";
import AutoResizeTextarea from "@components/input/AutoResizeTextarea";
import GuidePreview from "@components/manage/guide/GuidePreview";
import config from "@config";
import constants from "@constants";
import LoadingSpinner from "@components/LoadingSpinner";
import LoadingOverlay from "@components/common/LoadingOverlay";

const PointForm = () => {
  const { guide_id, point_id } = useParams();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { data: guideData, error, isLoading } = useGetGuideQuery(guide_id, { refetchOnMountOrArgChange: true });
  const [selectedLang, setSelectedLang] = useState('');
  const [newPoint] = useNewPointMutation();
  const [updatePoint] = useUpdatePointMutation();

  const [images, setImages] = useState([]);
  const [deletedImageIds, setDeletedImageIds] = useState([]);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSpeechSynthesisSupported, setIsSpeechSynthesisSupported] = useState(true);
  const [currentSpeakingLang, setCurrentSpeakingLang] = useState(null);

  const synth = window.speechSynthesis;
  const utteranceRef = useRef(null);

  useEffect(() => {
    if (!('speechSynthesis' in window)) {
      setIsSpeechSynthesisSupported(false);
    }

    return () => {
      if (isSpeechSynthesisSupported && utteranceRef.current) {
        synth.cancel();
      }
    }
  }, []);

  useEffect(() => {
    if (guideData) {
      if (guideData.support_language.includes(i18n.language)) {
        setSelectedLang(i18n.language);
      } else {
        setSelectedLang(guideData.support_language[0]);
      }
    }
  }, [guideData]);

  const validationSchema = Yup.object().shape({
    support_language: Yup.array().min(1, t('point.validation.language_required')),
    ...constants.common.LANGUAGES.reduce((acc, lang) => ({
      ...acc,
      [`name_${lang.code}`]: Yup.string().when('support_language', {
        is: (val) => val && val.includes(lang.code),
        then: schema => schema.required(t('point.validation.title_required')),
      }),
      [`description_${lang.code}`]: Yup.string().when('support_language', {
        is: (val) => val && val.includes(lang.code),
        then: schema => schema.required(t('point.validation.content_required')),
      }),
      [`audio_script_${lang.code}`]: Yup.string().when('support_language', {
        is: (val) => val && val.includes(lang.code),
        then: schema => schema.required(t('point.validation.script_required')),
      }),
    }), {}),
  });

  const { register, handleSubmit, watch, reset, setValue, formState: { errors } } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      support_language: [],
    }
  });

  const point = watch();

  useEffect(() => {
    if (guideData) {
      const pointData = guideData.points.find(point => point._id === point_id);
      if (pointData) {
        reset({
          support_language: guideData.support_language,
          images: pointData.images,
          ...constants.common.LANGUAGES.reduce((acc, lang) => ({
            ...acc,
            [`name_${lang.code}`]: pointData[`name_${lang.code}`],
            [`description_${lang.code}`]: pointData[`description_${lang.code}`],
            [`audio_script_${lang.code}`]: pointData[`audio_script_${lang.code}`],
          }), {}),
        });
  
        if (pointData.images) {
          setImages(pointData.images.map((image) => ({
            id: image.id || image._id,
            preview: `${config.API_SERVER_HOST}/files/point/${image.filename}?x=1280x720`,
            isExisting: true
          })));
        }
      } else {
        reset({ support_language: guideData.support_language });
      }
    }
  }, [guideData, reset, point_id]);

  useEffect(() => {
    setValue('images', images);
  }, [images]);

  const watchLanguages = watch("support_language", []);

  const onSubmit = async (data) => {
    setIsSubmitting(true);

    try {
      const formData = new FormData();
      formData.append('guide_id', guide_id);
      
      Object.keys(data).forEach(key => {
        if (key.startsWith('images')) {
          return;
        } else if (Array.isArray(data[key])) {
          data[key].forEach(value => formData.append(`${key}[]`, value));
        } else {
          formData.append(key, data[key]);
        }
      });

      images.forEach(async (image) => {
        if (!image.isExisting) {
          formData.append(`images`, image.file);
        }
      });

      deletedImageIds.forEach((id) => {
        formData.append(`deleted_image_ids[]`, id);
      });
      if (point_id) {
        const result = await updatePoint({ id: point_id, point: formData });
        console.log(result);
      } else {
        await newPoint(formData);
      }
      navigate(`/guides/${guide_id}`, { replace: true });
    } catch (error) {
      console.error('Failed to save point:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const speakText = (text, langCode) => {
    if (!isSpeechSynthesisSupported) return;

    synth.cancel();

    if (isSpeaking && currentSpeakingLang === langCode) {
      setIsSpeaking(false);
      return;
    }

    utteranceRef.current = new SpeechSynthesisUtterance(text);
    utteranceRef.current.lang = langCode;
    
    utteranceRef.current.onstart = () => {
      setIsSpeaking(true);
      setCurrentSpeakingLang(langCode);
      handleLanguageChange(langCode);
    };
    utteranceRef.current.onend = () => {
      setCurrentSpeakingLang(null);
      setIsSpeaking(false);
    };
    utteranceRef.current.onerror = () => {
      setCurrentSpeakingLang(null);
      setIsSpeaking(false);
    };

    setTimeout(() => {
      synth.speak(utteranceRef.current);
    }, 100);
  };

  if (isLoading) {
    return <LoadingSpinner isLoading={true} />;
  }

  if (error) {
    return (
      <Layout>
        <div className="text-center py-10">
          <p>{t('common.error_occurred')}</p>
        </div>
      </Layout>
    );
  }

  const handleLanguageChange = (langCode) => {
    setSelectedLang(langCode);
  };

  const getSubmitMessage = () => {
    if (point_id) {
      return t('point.updating_point');
    } else {
      return t('point.creating_point');
    }
  };

  return (
  <Layout previousPath={`/guides/${guide_id}`}>
      <LoadingOverlay isLoading={isSubmitting} message={getSubmitMessage()} />
      <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8 gap-4 grid grid-cols-1 md:grid-cols-4 2xl:grid-cols-3">
      <div className="col-span-1 md:col-span-2 2xl:col-span-2">
        <div className="bg-white shadow-md rounded-lg p-6">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 flex items-center">
                {t('point.detailed_images')}
                <InfoTooltip content={t('point.detailed_images_info')} />
              </label>
              <MultiImageUpload
                initialImages={images}
                onImagesChange={setImages}
                onDeletedImagesChange={setDeletedImageIds}
              />
            </div>

            {watchLanguages.length > 0 && (
              <div className="space-y-6">
                {constants.common.LANGUAGES.filter(lang => watchLanguages.includes(lang.code)).map((lang) => (
                  <div key={lang.code} className="bg-gray-50 p-4 rounded-lg shadow-sm">
                    <h4 className="text-lg font-medium text-gray-900 mb-4">{lang.name}</h4>
                    <div className="mb-4">
                      <label htmlFor={`name_${lang.code}`} className="block text-sm font-medium text-gray-700 flex items-center">
                        {t('point.point_title')}
                        <InfoTooltip content={t('point.point_title_info')} />
                      </label>
                      <input
                        type="text"
                        id={`name_${lang.code}`}
                        {...register(`name_${lang.code}`, {
                          onChange: () => {
                            if (lang.code !== selectedLang) {
                              setSelectedLang(lang.code);
                            }
                          }
                        })}
                        className="mt-1 px-2 py-1 w-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                        placeholder={t(`point.placeholders.point_title.${lang.code}`)}
                      />
                      {errors[`name_${lang.code}`] && <p className="mt-2 text-sm text-red-600">{errors[`name_${lang.code}`].message}</p>}
                    </div>
                    <div className="mb-4">
                      <label htmlFor={`description_${lang.code}`} className="block text-sm font-medium text-gray-700 flex items-center">
                        {t('point.content_display')}
                        <InfoTooltip content={t('point.content_display_info')} />
                      </label>
                      <AutoResizeTextarea
                        id={`description_${lang.code}`}
                        {...register(`description_${lang.code}`, {
                          onChange: () => {
                            if (lang.code !== selectedLang) {
                              setSelectedLang(lang.code);
                            }
                          }
                        })}
                        className="mt-1 px-2 py-1 w-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 leading-loose"
                        placeholder={t(`point.placeholders.point_content.${lang.code}`)}
                      ></AutoResizeTextarea>
                      {errors[`description_${lang.code}`] && <p className="mt-2 text-sm text-red-600">{errors[`description_${lang.code}`].message}</p>}
                    </div>
                    <div className="mb-4">
                      <label htmlFor={`audio_script_${lang.code}`} className="block text-sm font-medium text-gray-700 flex items-center">
                        {t('point.script_tts')}
                        <InfoTooltip content={t('point.script_tts_info')} />
                      </label>
                      <div className="flex items-start">
                        <AutoResizeTextarea
                          id={`audio_script_${lang.code}`}
                          {...register(`audio_script_${lang.code}`, {
                            onChange: () => {
                              if (lang.code !== selectedLang) {
                                setSelectedLang(lang.code);
                              }

                              if (isSpeaking && currentSpeakingLang === lang.code) {
                                synth.cancel();
                                setIsSpeaking(false);
                                setCurrentSpeakingLang(null);
                              }
                            }
                          })}
                          className="mt-1 px-2 py-1 w-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 leading-loose"
                          placeholder={t(`point.placeholders.point_script.${lang.code}`)}
                        ></AutoResizeTextarea>
                        <button
                          type="button"
                          onClick={() => speakText(watch(`audio_script_${lang.code}`), lang.code)}
                          // disabled={isSpeaking && selectedLang !== lang.code || !watch(`audio_script_${lang.code}`)}
                          className={`ml-4 inline-flex items-center px-3 py-2 border border-transparent text-sm font-medium rounded-md text-white whitespace-nowrap w-24 justify-center ${
                            isSpeaking && currentSpeakingLang === lang.code
                              ? 'bg-red-600 hover:bg-red-700'
                              : 'bg-indigo-600 hover:bg-indigo-700'
                          } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 
                          
                          `}
                        >
                          {/* ${(!watch(`audio_script_${lang.code}`) || (isSpeaking && selectedLang !== lang.code)) ? 'opacity-50 cursor-not-allowed' : ''} */}
                          {isSpeaking && selectedLang === lang.code ? t('point.tts.stop') : t('point.tts.speak')}
                        </button>
                      </div>
                      {errors[`audio_script_${lang.code}`] && <p className="mt-2 text-sm text-red-600">{errors[`audio_script_${lang.code}`].message}</p>}
                      <p className="mt-2 text-sm text-gray-500">{t('point.tts.device_difference_notice')}</p>
                    </div>
                  </div>
                ))}
              </div>
            )}

            <div className="mt-6 flex justify-end">
              <button
                type="submit"
                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                {point_id ? t('point.update_point') : t('point.create_point')}
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="hidden col-span-1 md:col-span-2 2xl:col-span-1 md:block">
        <div className="sticky top-4">
          <GuidePreview guideData={guideData} point={point} selectedLang={selectedLang} onLanguageChange={handleLanguageChange} />
        </div>
      </div>
    </div>
    </Layout>
  );
};

export default PointForm;